import React from 'react';

import './textInput.css';

export const TextInput = ({
  field,
  form: {touched, errors},
  title,
  half,
  ...props
}) => {
  return (
    <div className={`text-input ${half ? 'half-input' : ''}`}>
      <span className="input-title">{title}</span>
      <input className="input" class="input" {...field} {...props} />
      {touched[field.name] && errors[field.name] && (
        <div className="error">{errors[field.name]}</div>
      )}
    </div>
  );
};

export const Input = ({
  name,
  onChange,
  value,
  title,
  half,
  placeholder,
  disabled,
}) => (
  <div className={`text-input ${half ? 'half-input' : ''}`}>
    <span className="input-title">{title}</span>
    <input
      className="input"
      class="input"
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);
