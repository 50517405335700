import React from 'react';
import {NavLink} from 'react-router-dom';

import './Menu.css';

export const Menu = ({token, logout, showMenu}) => {
  return (
    <div>
      <div className="side-menu-nav">
        <NavLink
          className={({isActive}) => (isActive ? 'active' : undefined)}
          to="/explore">
          Explore
        </NavLink>
        {/* <NavLink
          className={({isActive}) => (isActive ? 'active' : undefined)}
          to="/realestate">
          Real Estate
        </NavLink> */}
        {token && showMenu ? (
          <>
            <NavLink
              className={({isActive}) => (isActive ? 'active' : undefined)}
              to="/orders">
              Orders
            </NavLink>

            <NavLink
              className={({isActive}) => (isActive ? 'active' : undefined)}
              to="/subscriptions">
              Healthcare
            </NavLink>
            <NavLink
              className={({isActive}) => (isActive ? 'active' : undefined)}
              to="/account">
              Account
            </NavLink>
            <a className="logout" Click={() => logout()}>
              Logout
            </a>
          </>
        ) : (
          <NavLink
            className={({isActive}) =>
              isActive ? 'active loginButton' : 'loginButton'
            }
            to="/">
            Login
          </NavLink>
        )}
      </div>
    </div>
  );
};
